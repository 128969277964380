import { Fragment, useState } from "react";
import {
  Alert,
  Box,
  Container,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  styled,
  Button,
  useTheme,
  useMediaQuery,
  ListItemButton,
  Collapse,
} from "@mui/material";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import { NavLink } from "react-router-dom";
import useAuth from "../../contexts/auth";
import {
  Add,
  AdminPanelSettings,
  ExpandLess,
  ExpandMore,
  SettingsOutlined,
  MailOutlined,
} from "@mui/icons-material";
import { US_STATES } from "../../constants";
import { useNavigate, useMatch } from "react-router-dom";

const styles = {
  root: {
    display: "flex",
    height: "100vh",
  },
  main: {
    flexGrow: 1,
  },
  content: {
    minHeight: "75vh",
  },
  activeListItem: {
    backgroundColor: "#3a4248",
  },
  listIcon: {
    color: "#fff",
    minWidth: "40px",
  },
  hide: {
    display: "none",
  },
  listTitle: {
    fontWeight: "bold",
  },
  listDivider: {
    backgroundColor: "#fff",
  },
  activeListIcon: {
    color: "#20a8d8",
  },
};

const LinkListItem = styled(ListItem)(({ theme }) => ({
  color: "#fff",
  "&:hover": {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
}));

function LinkItem({ isActive, name, icon, title, sx, ...props }) {
  return (
    <LinkListItem
      sx={{
        ...(isActive && styles.activeListItem),
        ...sx,
      }}
      component="div"
      {...props}
    >
      {icon && (
        <ListItemIcon
          sx={{
            ...styles.listIcon,
            ...(isActive && styles.activeListIcon),
          }}
        >
          {icon}
        </ListItemIcon>
      )}

      <ListItemText
        primary={name}
        sx={{
          fontWeight: title ? "bold" : "normal",
        }}
      />
    </LinkListItem>
  );
}

function DefaultLayout({ children }) {
  const { adminUser, user, logout } = useAuth();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [drawerOpened, openDrawer] = useState(false);

  const match = useMatch("/home/user/leads/:county");
  const navigate = useNavigate();

  const countyName = match?.params?.county;

  const [openStates, setOpenStates] = useState(
    countyName ? [countyName.split(", ")[1]] : []
  );

  const toggleDrawer = () => {
    openDrawer(!drawerOpened);
  };

  const onAdminLogout = () => {
    logout().then(() => {
      window.location = process.env.REACT_APP_API_URL + "/admin/resources/User";
    });
  };

  const { firmname, subscribedCounties } = user;

  const groupedCounties =
    subscribedCounties?.reduce((acc, county) => {
      if (!acc[county.state]) {
        acc[county.state] = [];
      }

      acc[county.state].push(county);

      return acc;
    }, {}) || {};

  const navigation = Object.entries(groupedCounties).sort((a, b) =>
    a[0].localeCompare(b[0])
  );

  const handleStateClick = (state) => {
    if (openStates.includes(state)) {
      setOpenStates(openStates.filter((s) => s !== state));
    } else {
      setOpenStates([...openStates, state]);
    }
  };

  const changeState = (state) => {
    navigate(`/home/user/leads/${state}`);
  };

  const drawerContent = (
    <>
      <Toolbar />

      <List>
        <ListItem>
          <ListItemText
            primary={"WELCOME " + [firmname]}
            sx={{
              fontWeight: "bold",
            }}
          />
        </ListItem>

        <li>
          <Divider sx={styles.listDivider} />
        </li>

        {user?.admin && (
          <ListItem
            disablePadding
            component="div"
            sx={{ display: { xs: "block", sm: "none" } }}
          >
            <li>
              <a href={`${process.env.REACT_APP_API_URL}/admin`}>
                <LinkItem name="Admin panel" icon={<AdminPanelSettings />} />
              </a>
            </li>
            <li>
              <Divider sx={styles.listDivider} />
            </li>
          </ListItem>
        )}

        <ListItem disablePadding sx={{ display: { xs: "block", sm: "none" } }}>
          <NavLink to="/subscriptions/new">
            {({ isActive }) => (
              <LinkItem
                isActive={isActive}
                name="Add Subscription"
                icon={<Add />}
              />
            )}
          </NavLink>
        </ListItem>

        {navigation.length > 0 &&
          navigation.map(([state, counties], i) => {
            const stateName = US_STATES[state];
            const openList = openStates.includes(stateName);

            return (
              <Fragment key={state}>
                <ListItemButton style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItemText
                    primary={stateName}
                    onClick={() => changeState(state)}
                    sx={{ fontWeight: "bold", paddingTop: 1, paddingBottom: 1 }}
                  />

                  <div onClick={() => handleStateClick(stateName)}>
                    {openList ? <ExpandLess /> : <ExpandMore />}
                  </div>
                </ListItemButton>

                <Collapse
                  component="li"
                  in={openList}
                  timeout="auto"
                  unmountOnExit
                >
                  <List disablePadding>
                    {counties.map((c) => (
                      <li key={`${state}-${c.countyPractice}`}>
                        <NavLink to={"/home/user/leads/" + c.countyPractice}>
                          {({ isActive }) => (
                            <LinkItem
                              sx={{ pl: 4 }}
                              isActive={isActive}
                              name={c.countyPractice.split(", ")[0]}
                            />
                          )}
                        </NavLink>
                      </li>
                    ))}
                  </List>
                </Collapse>
              </Fragment>
            );
          })}
        <li>
          <Divider sx={styles.listDivider} />
        </li>

        {user.mailchimpApiKey ? (
          <li style={{ padding: 0 }}>
            <NavLink to="/home/user/addMailchimp">
              {({ isActive }) => (
                <LinkItem
                  isActive={isActive}
                  name="Email Automation"
                  icon={<SettingsOutlined />}
                />
              )}
            </NavLink>
          </li>
        ) : (
          <li style={{ padding: 0 }}>
            <NavLink to="/email">
              {({ isActive }) => (
                <LinkItem
                  isActive={isActive}
                  name="Email Automation"
                  icon={<MailOutlined />}
                />
              )}
            </NavLink>
          </li>
        )}

        <li style={{ padding: 0 }}>
          <NavLink to="/home/user/sms-automation">
            {({ isActive }) => (
              <LinkItem
                isActive={isActive}
                name="SMS Automation"
                icon={<SettingsOutlined />}
              />
            )}
          </NavLink>
        </li>

        <li style={{ padding: 0 }}>
          <NavLink to="/subscriptions/manage">
            {({ isActive }) => (
              <LinkItem
                isActive={isActive}
                name="Manage Subscriptions"
                icon={<Add />}
              />
            )}
          </NavLink>
        </li>
      </List>
    </>
  );

  return (
    <div style={styles.root}>
      <DefaultHeader toggleDrawer={toggleDrawer} />

      <Drawer
        variant={smallScreen ? "temporary" : "permanent"}
        open={smallScreen ? drawerOpened : true}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: smallScreen, // Better open performance on mobile.
        }}
        sx={{
          "& .MuiDrawer-paper": { boxSizing: "border-box" },
        }}
      >
        {drawerContent}
      </Drawer>

      <Box as="main" style={styles.main} pt={8}>
        <Container>
          {adminUser && (
            <Alert severity="error" sx={{ width: "100%" }}>
              <span>
                You are impersonating <strong>{user.username}</strong> (
                {user.email})
              </span>
              <br />
              <span>
                <Button
                  variant="outlined"
                  onClick={onAdminLogout}
                  color="error"
                >
                  LOG OUT
                </Button>{" "}
                and sign back in as <strong>{adminUser.email}</strong> (
                {adminUser.username})
              </span>
            </Alert>
          )}

          {/* {(countyName === "CA" || countyName?.endsWith("California")) && (
            <Alert
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
              style={{ fontSize: "16px", margin: "20px 0" }}
            >
              <span>
                Due to the fire situation and need to have all communication
                lines open for emergency assistance, no text messages will be
                sent in Los Angeles County.
              </span>
              <p>
                We appreciate your understanding and send our support to those
                impacted by the fires.
              </p>
            </Alert>
          )} */}

          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            sx={styles.content}
            p={1}
          >
            {children}
          </Box>
        </Container>

        <DefaultFooter />
      </Box>
    </div>
  );
}

export default DefaultLayout;
